<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('免责声明')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <template v-if="lang == 'zh'">
                <pre>
    有关使用 AFOUR 平台的重要信息和风险免责声明

通过访问网站 https://www.AFOUR.bet 及其任何网页以及 AFOUR 移动应用程序(可在适用于iOS 和Android 移动 设备操作系统的应用商店免费下载)(统称为“平台”),您 承认您已阅读以下信息并接受下面列出的条款和条件,并同 意受本文件的约束。本文件中的术语“您”或“您的”是指您个 人以及根据上下文需要您代表其访问平台的任何其他人。我 们可能会不时更改本文件以及您与平台之间的其他文件或协 议,并在平台上发布此类更改,恕不另行通知。通过访问或 使用平台,您将被视为已同意本文档的条款以及平台上发布 的其他文件和协议。 一般的 该平台以商品名“AFOUR”(“AFOUR”、“我们”或“我

们”)在全球运营。

平台内容未经任何监管机构审查。如果您对平台的内容有任 何疑问,您应该寻求独立的专业建议。

本平台不针对任何司法管辖区(包括但不限于阿富汗、古

巴、伊朗、朝鲜、叙利亚和加拿大安大略省),也无意分发

给任何人或由其访问或使用。平台上的信息将违反该司法管 辖区的法律或法规,或者会使AFOUR 受到该司法管辖区的 任何注册或制裁合规要求。适用此类禁令的人员不得访问平 台。在某些司法管辖区,包括但不限于阿富汗、古巴、伊 朗、朝鲜、叙利亚和加拿大安大略省,访问或下载平台上的 信息可能是非法的,并且在法律允许的最大范围内,我们否 认所有如果您从平台访问或下载任何信息违反了您居住或当 时所在司法管辖区的任何法律或法规,则应承担责任。通过 继续操作,您声明并保证您所在司法管辖区的适用法律和法 规允许您访问平台上的信息。

针对美国人的具体信息

平台上的信息不针对或旨在分发给美国任何州、领土或属地 的居民或法规第902(k)条含义内的任何其他“美国人”,或 由其使用S 根据经修订的1933年证券法(“证券法”)。

平台上描述的任何数字资产均未根据《证券法》进行注册, 并且不得在美国或向美国人提供或出售,除非根据《证券 法》注册要求的适用豁免或根据任何决定美国证券交易委员 会对此类数字资产进行监管。

平台上显示的信息包含可能被美国有关当局解释为购买或出 售数字资产的要约或要约邀请的材料。因此,平台上的信息 仅供不属于旨在监管数字资产的要约、销售或其他交易的美 国任何法律范围的人员查看。如果您不确定自己在美国法律 下的地位,您应该在访问平台之前寻求律师的建议。

加拿大居民的具体信息

加拿大的任何证券委员会或类似机构均未以任何方式审查或 传递平台上信息的优点,任何相反的陈述均属犯罪。

无报价

平台内容仅供参考和公平交易之用,并不构成任何购买、要 约出售的招揽,或购买、出售或以其他方式获取或处置任何 权益的建议或建议。任何投资,或追求任何投资策略的任何 建议或推荐。

没有建议

该平台允许已注册在 AFOUR 开立账户的客户以主体身份与 AFOUR 交易数字资产。平台接受客户订单后,可以根据汇 总的未平仓头寸向 AFOUR 的交易对手平台(例如火币或币 安)下订单,以便 AFOUR 在平台上完成客户的订单。在与 客户的交易以及与交易对手的交易中,AFOUR 均以主要身 份行事。客户可以利用 AFOUR 提供的各种专有自动交易系 统(“交易机器人”)通过填写所选交易机器人的交易参数来执行交易。任何交易机器人的操作都是由客户设置的参数决 定的。在没有客户输入的情况下,任何交易机器人都无法对 任何交易头寸行使任何酌情权。客户可以随时停止任何交易 机器人的运行,并随时提取所有涉及的数字资产,包括本金 和已实现的损益。

AFOUR 未在任何司法管辖区获得经纪人、财务顾问、投资 顾问、投资组合经理或税务顾问的许可。平台中包含的任何 内容均不构成投资、法律、税务或其他建议。您不应依赖平 台的内容作为建议或做出任何决定。在做出任何交易或投资 决定之前,您应针对您的具体情况获取专业建议,并获取并 阅读与任何交易或投资相关的条款和条件以及其他信息,包 括与此类交易或投资相关的风险披露。AFOUR 不会对平台 上进行的任何交易或投资的适用性做出任何决定,也不表示 或保证任何此类交易或投资适合您。平台上引用的任何交易 或投资的任何风险声明不应被视为对所有风险的全面披露。

通过访问平台,您承认并同意 AFOUR 对您从平台获得的任 何信息的使用不承担任何责任,您的交易或投资决策是您自 己做出的,您承担全部责任,并且您使用平台和交易机器人 的风险由您自行承担。此外,AFOUR 不承担因您使用从平 台获得的任何信息而引起的任何责任。

数字资产交易和投资的风险

作为平台的客户或潜在客户,在向AFOUR 支付任何款项或 数字资产之前,您应了解以下事项:

AFOUR 可能不持有通过您所在司法管辖区的平台向您提供 服务的许可。请注意,这可能意味着如果我们的业务失败, 您将无法收回支付给我们的所有资金或数字资产。

如果您不熟悉该数字资产,则不应进行该数字资产交易。数 字资产交易可能不适合您。您一旦在平台进行交易,您的行 为将被视为您自愿,并被视为您完全熟悉数字资产和法币 (若涉及交易)。

您应该意识到,数字资产的价值可能会大幅波动。仅当您愿 意并准备好接受损失所有投入此类资产的资金的风险时,您 才应该购买数字资产。

过去的表现并不代表未来可能的表现,平台上可用的任何数 字资产的价值可能会下跌或上涨。AFOUR 不保证任何交易 或投资的表现、任何客户本金的返还或任何回报率的实现。

投资平台上提供的任何数字资产都存在重大风险,包括可能 损失本金。任何数字资产的性能和回报都可能随着时间的推 移而发生显着变化。

平台上提供的数字资产交易和投资并不适合所有人。您仅应 在彻底审查数字资产的相关信息后才可交易或投资数字资 产。在进行任何交易之前,您可能希望咨询独立财务顾问以 获得个人和具体的交易和投资建议。

我们可以自行决定在第三方数字资产钱包解决方案提供商和/ 或外部数字资产交易对手交易所(均称为“外部提供商”)开 设和/或维护外部钱包/账户,以保管和保管全部或部分您的 数字资产,和/或促进您通过AFOUR 进行数字资产交易和 投资。虽然我们将采取措施仔细选择和聘用外部提供商,但 AFOUR 不保证外部提供商的软件或技术的安全性或功能 性,也不对由于外部提供商的软件或技术故障而导致的任何 数字资产损失负责。因此,您可能会面临丢失已存放在外部 提供商处的数字资产的风险,AFOUR 不会对此类损失承担 责任。此外,在此情况下,AFOUR 不予赔偿。

我们使用各种区块链和/或区块链技术来处理您的数字资产交 易,例如以太坊区块链。所有区块链和/或区块链技术都可能 经历意外事件或后果,包括但不限于积压、高于正常交易费 用、网络更改、故障或协议分叉。AFOUR 不拥有或控制任 何区块链或区块链技术,不对区块链网络的运营负责,也不 对区块链网络的安全性、功能性或可用性做出任何保证。您 可能因任何此类事件或后果而遭受损失,AFOUR 不会就此 类损失向您进行赔偿。您承认并接受,我们拥有自行决定对 任何数字资产协议的任何操作变更的响应,并且我们没有责 任为您提供不受支持的货币或协议的帮助。 无保证

虽然 AFOUR 真诚地相信平台上提供的信息是准确且最新 的,但它不对此类信息的准确性或完整性做出任何陈述或保 证,并且不对任何直接、间接、后果性的后果承担责任。因 使用平台(包括使用平台上指向外部网站或资源的任何链 接)或使用此类信息而产生或与之相关的任何类型的特殊损 失或损害。平台上的信息仅供参考和公平交易之用,并不构 成交易或投资建议。除公平交易外,不得出于任何目的依赖 平台上提供的信息或其准确性或完整性,并且AFOUR 及其 任何附属公司、董事、管理人员或员工均不承担与以下方面 有关的任何责任或义务:平台上提供的任何信息如有更改, 恕不另行通知。平台上的任何内容都不是针对任何人的特定 需求而定制的。未经AFOUR 事先明确书面同意,平台上的 内容不得用作任何金融工具、商品或其他产品的基础。

平台上的某些内容,包括通过外部网站、平台或来源的超链 接向平台提供的内容,是由无关的第三方提供商(包括数字 资产定价信息提供商)提供给AFOUR的。虽然AFOUR 基 于严格的标准和合理的谨慎选择此类第三方内容提供商,但 AFOUR 不会审查此类第三方内容的准确性、完整性或可靠 性,并且不保证或保证其准确性、完整性、可靠性或任何其 他方面此类内容。平台的性能与某些不相关的第三方服务的 性能直接相关。AFOUR 不对此类第三方服务的不履行承担 任何责任,也不对因此类第三方服务的故障而导致的平台性 能问题承担任何责任。

数字资产及其交易和投资基于新的且未经充分测试的技术。 除了本文所述的风险外,还存在与您使用平台以及购买、持 有、出售和使用数字资产相关的其他风险,其中许多风险是 AFOUR 不知道也无法预测的。此类风险可能会以意想不到 的方式实现,作为本文讨论的或其他未知风险的变化或组 合。

在适用法律允许的最大范围内,AFOUR 特此否认有关平台 可用性的所有默示保证。除非本协议明确规定,否则平台按 “原样”和“可用”方式向您提供,不提供任何形式的保证。 AFOUR、其附属公司、董事、管理人员、员工和代理人明确 否认所有其他明示或暗示的陈述、认可或条件保证,包括但 不限于适销性、特定用途的适用性、所有权或不侵权、完整 性、安全性、可靠性、适用性、准确性、流通性、可用性、 无错误或不间断的操作,或缺陷将得到纠正,或因交易、履 行或贸易使用过程而产生的任何保证。对于因您使用本平台

而产生的任何索赔,您唯一且排他的补救措施以及我们对您

或任何第三方的唯一义务是,您可以随时停止使用本平台。

在适用法律允许的最大范围内,AFOUR 不保证或保证可从 平台下载的文件或数据没有病毒或其他有害代码或组件。您 对平台的使用以及您的设备和数据安全承担全部责任。在适 用法律允许的最大范围内,AFOUR、其附属公司、董事、管 理人员、员工和代理将不对拒绝服务攻击、分布式拒绝服务 攻击、超载、洪水造成的任何损失或损害承担责任。、邮件 轰炸或崩溃、病毒、特洛伊木马、蠕虫、逻辑炸弹或其他可 能因您使用平台或下载而感染您的计算机设备、移动设备、 计算机程序、数据或其他专有材料的技术有害材料在平台或 与平台链接的任何第三方平台上发布的任何材料。

责任限制

除本使用条款中明确提及的声明或保证外,AFOUR 不做出 任何声明或保证。该平台的开发并不是为了满足您的个人需 求。

在适用法律允许的最大范围内,您承认并同意 AFOUR 及其 附属公司、董事、管理人员、雇员或代理人不对您可能遭受 的任何直接、间接、附带、特殊、后果性或惩戒性损害承担 责任与您使用本平台相关的任何责任理论,包括但不限于利 润损失、机会损失、数据丢失和其他无形损失。通过访问平 台,您承认您充分了解交易、投资和使用数字资产的风险, 并对这些风险承担全部责任。

反洗钱和反恐融资(AML/CTF)和监管合规

AFOUR 致力于遵守与 AML/CTF 相关的所有适用法律、法 规以及监管标准和指南,包括但不限于金融行动特别工作组 发布的《打击洗钱和恐怖主义融资及扩散国际标准》;美国 《银行保密法》和《美国爱国者法》;欧盟第六号反洗钱指 (2021年6月3日生效);英国金融行为监管局实施的 《反洗钱条例》;加拿大《犯罪收益(洗钱)和恐怖主义融 资法》;《反洗钱及恐怖分子资金筹集条例》(香港法例第 615章)和《香港反洗钱及反恐融资指引》。

AFOUR 实施了强大的AML/CTF 合规计划,符合其所遵守 的所有适用的AML/CTF要求。

如下面的隐私政策所述,您同意 AFOUR 收集和使用您的个 人数据,以使 AFOUR 遵守 AML/CTF 和其他监管要求。

网站和软件的可用性

该平台可在运行 AFOUR 应用程序的计算机和手持移动设备 上使用。AFOUR 将尽最大努力使平台始终可用。然而,您 理解并承认该平台是通过互联网和移动网络提供的,因此平 台的质量和可用性可能会受到 AFOUR 合理控制之外的因素 的影响。

AFOUR 不对平台不可用、下载或访问平台内容出现任何困 难或无法、或可能导致平台无法完全可用或运行的任何其他 系统故障承担任何责任。

AFOUR 不负责向您提供有关平台的任何技术支持或维护。 AFOUR 可以自行决定不时更新、修改或调整平台及其功 能,以增强客户体验。AFOUR 对这些操作造成的任何停机 概不负责。

服务台

AFOUR 在平台上维护着一个在线服务台,可以回答您有关 平台的问题。AFOUR 将仅解决有关平台功能的问题,不会 向您提供任何个性化建议或推荐您个人计算机或移动设备上 的任何系统设置。AFOUR可能会在平台上上传一般教程和 说明视频来介绍平台的功能。AFOUR上传的所有教程、视 频和其他说明材料仅供一般使用,不包含任何个性化解决方 案。您使用所有此类服务和材料的风险由您自行承担。

隐私

AFOUR 致力于保护根据所有适用的数据保护法收集的客户 个人信息或个人数据的隐私、机密性和安全性。AFOUR 同 样致力于确保我们所有员工遵守我们有关数据安全和保密的 所有内部政策和标准,并保护您的个人数据免遭未经授权的 访问、非法处理或意外丢失、毁坏或损坏。
                </pre>
            </template>
            <template v-else>
                <pre>
    IMPORTANT INFORMATION AND RISK DISCLAIMER REGARDING THE USE OF THE AFOUR PLATFORM

By accessing the website https://www.AFOUR.bet and any of its web pages and the AFOUR mobile application (available for free download in the app stores for iOS and Android mobile device operating systems) (collectively, the “Platform”), you acknowledge that you have read the following information and accept the terms and conditions set out below and agree to be bound by this document. The terms “you” or “your” in this document refer to you personally and any other person on whose behalf you are accessing the Platform as the context requires. We may change this document and other documents or agreements between you and the Platform from time to time and post such changes on the Platform without prior notice. By accessing or using the Platform, you will be deemed to have agreed to the terms of this document and other documents and agreements posted on the Platform. GENERAL The Platform operates globally under the trade name “AFOUR” (“AFOUR”, “we” or “us”).

The Platform Content has not been reviewed by any regulatory authority. If you have any questions about the content of the Platform, you should seek independent professional advice.

The Platform is not targeted at, nor intended for distribution to, or access or use by, any person in any jurisdiction (including, but not limited to, Afghanistan, Cuba, Iran, North Korea, Syria and Ontario, Canada). The information on the Platform would violate the laws or regulations of such jurisdiction or would subject AFOUR to any registration or sanctions compliance requirements of such jurisdiction. Persons to whom such prohibitions apply are prohibited from accessing the Platform. In certain jurisdictions, including, but not limited to, Afghanistan, Cuba, Iran, North Korea, Syria and Ontario, Canada, it may be illegal to access or download the information on the Platform, and to the maximum extent permitted by law, we disclaim all liability if you access or download any information from the Platform in violation of any laws or regulations of the jurisdiction in which you reside or are located at the time. By proceeding, you represent and warrant that the applicable laws and regulations of your jurisdiction permit you to access the information on the Platform.

Specific Information for U.S. Persons

The information on the Platform is not directed to or intended for distribution to residents of any state, territory, or possession of the United States or any other “U.S. Person” within the meaning of Rule 902(k) of the Code, or for use by residents of the United States under the Securities Act of 1933, as amended (the “Securities Act”).

None of the digital assets described on the Platform have been registered under the Securities Act and may not be offered or sold in the United States or to U.S. Persons except pursuant to an applicable exemption from the registration requirements of the Securities Act or pursuant to any determination by the U.S. Securities and Exchange Commission that such digital assets are regulated.

The information displayed on the Platform contains material that may be construed by the relevant U.S. authorities as an offer or solicitation of an offer to buy or sell digital assets. Accordingly, the information on the Platform is intended only for viewing by persons who are not subject to any laws of the United States that are intended to regulate offers, sales, or other transactions in digital assets. If you are unsure of your position under U.S. law, you should seek the advice of counsel before accessing the Platform.

Specific Information for Canadian Residents

No securities commission or similar body in Canada has in any way reviewed or passed on the merits of the information on the Platform, and any representation to the contrary would be a criminal offense.

No Quotes

The Platform Content is for reference and fair trading purposes only and does not constitute any solicitation to buy, offer to sell, or any advice or recommendation to buy, sell or otherwise acquire or dispose of any interest. Any investment, or any advice or recommendation to pursue any investment strategy.

No Advice

The Platform allows customers who have registered and opened an account with AFOUR to trade digital assets with AFOUR as a principal. After the Platform accepts the customer's order, it can place an order with AFOUR's counterparty platform (such as Huobi or Binance) based on the aggregated open positions so that AFOUR can complete the customer's order on the Platform. AFOUR acts as a principal in both transactions with customers and transactions with counterparties. Customers can use various proprietary automated trading systems ("Trading Robots") provided by AFOUR to execute transactions by filling in the trading parameters of the selected Trading Robot. The operation of any Trading Robot is determined by the parameters set by the customer. No Trading Robot can exercise any discretion on any trading position without customer input. Clients may stop the operation of any trading robot at any time and withdraw all involved digital assets, including principal and realized gains and losses, at any time.

AFOUR is not licensed as a broker, financial advisor, investment advisor, portfolio manager or tax advisor in any jurisdiction. Nothing contained in the Platform constitutes investment, legal, tax or other advice. You should not rely on the content of the Platform as advice or to make any decision. Before making any trading or investment decision, you should obtain professional advice for your specific circumstances and obtain and read the terms and conditions and other information related to any transaction or investment, including the risk disclosure associated with such transaction or investment. AFOUR does not make any decision on the suitability of any transaction or investment made on the Platform, nor does it represent or guarantee that any such transaction or investment is suitable for you. Any risk statement for any transaction or investment quoted on the Platform should not be regarded as a comprehensive disclosure of all risks.

By accessing the Platform, you acknowledge and agree that AFOUR is not responsible for the use of any information you obtain from the Platform, that your trading or investment decisions are yours and you assume full responsibility, and that your use of the Platform and trading robots is at your own risk. In addition, AFOUR does not assume any liability arising from your use of any information obtained from the Platform.

Risks of Digital Asset Trading and Investment

As a client or potential client of the Platform, before paying any money or digital assets to AFOUR, you should be aware of the following:

AFOUR may not hold a license to provide services to you through the Platform in your jurisdiction. Please note that this may mean that you will not be able to recover any funds or digital assets paid to us if our business fails.

If you are not familiar with the digital asset, you should not trade in the digital asset. Digital asset trading may not be suitable for you. Once you trade on the Platform, your actions will be deemed to be voluntary and that you are fully familiar with digital assets and fiat currencies (if trading is involved).

You should be aware that the value of digital assets may fluctuate significantly. You should only purchase digital assets if you are willing and prepared to accept the risk of losing all funds invested in such assets.

Past performance is not indicative of possible future performance, and the value of any digital asset available on the Platform may fall or rise. AFOUR does not guarantee the performance of any transaction or investment, the return of any client's principal, or the realization of any rate of return.

Investing in any digital asset offered on the Platform carries significant risks, including the possibility of losing principal. The performance and returns of any digital asset may vary significantly over time.

Trading and investing in digital assets offered on the Platform are not suitable for everyone. You should only trade or invest in digital assets after thoroughly reviewing the relevant information about the digital assets. Before making any transactions, you may wish to consult an independent financial advisor for personal and specific trading and investment advice.

We may, at our sole discretion, open and/or maintain external wallets/accounts with third-party digital asset wallet solution providers and/or external digital asset counterparty exchanges (each, an “External Provider”) to hold and safekeep all or part of your digital assets and/or facilitate your digital asset trading and investments through AFOUR. While we will take steps to carefully select and engage External Providers, AFOUR does not guarantee the security or functionality of the External Providers’ software or technology, and is not responsible for any loss of digital assets due to failure of the External Providers’ software or technology. Therefore, you may be at risk of losing digital assets that have been deposited with the External Providers, and AFOUR will not be liable for such losses. In addition, AFOUR will not compensate you in such circumstances.

We use various blockchains and/or blockchain technologies to process your digital asset transactions, such as the Ethereum blockchain. All blockchains and/or blockchain technologies may experience unexpected events or consequences, including, but not limited to, backlogs, higher than normal transaction fees, network changes, failures, or protocol forks. AFOUR does not own or control any blockchain or blockchain technology, is not responsible for the operation of blockchain networks, and does not make any guarantees about the security, functionality or availability of blockchain networks. You may suffer losses as a result of any such events or consequences, and AFOUR will not compensate you for such losses. You acknowledge and accept that we have the sole discretion to respond to any operational changes to any digital asset protocol, and we have no responsibility to provide you with assistance for unsupported currencies or protocols. No Warranty

While AFOUR sincerely believes that the information provided on the Platform is accurate and up-to-date, it makes no representations or warranties as to the accuracy or completeness of such information and is not responsible for any direct, indirect, consequential, or special losses or damages of any kind arising out of or in connection with the use of the Platform (including the use of any links on the Platform to external websites or resources) or the use of such information. The information on the Platform is provided for reference and fair trading purposes only and does not constitute trading or investment advice. The information provided on the Platform or its accuracy or completeness may not be relied upon for any purpose other than fair dealing, and neither AFOUR nor any of its affiliates, directors, officers or employees assumes any responsibility or liability in connection with: Any information provided on the Platform is subject to change without notice. None of the content on the Platform is tailored to the specific needs of any person. The content on the Platform may not be used as the basis for any financial instrument, commodity or other product without AFOUR's prior express written consent.

Certain content on the Platform, including content provided to the Platform via hyperlinks from external websites, platforms or sources, is provided to AFOUR by unrelated third-party providers, including digital asset pricing information providers. While AFOUR selects such third-party content providers based on strict criteria and reasonable care, AFOUR does not review the accuracy, completeness or reliability of such third-party content and does not warrant or guarantee the accuracy, completeness, reliability or any other aspect of such content. The performance of the Platform is directly related to the performance of certain unrelated third-party services. AFOUR assumes no liability for the non-performance of such third-party services, nor for platform performance issues caused by the failure of such third-party services.

Digital assets and their trading and investment are based on new and inadequately tested technologies. In addition to the risks described herein, there are other risks associated with your use of the Platform and the purchase, holding, sale and use of digital assets, many of which are unknown and unpredictable to AFOUR. Such risks may materialize in unexpected ways as a variation or combination of the risks discussed herein or other unknown risks.

To the maximum extent permitted by applicable law, AFOUR hereby disclaims all implied warranties regarding the availability of the Platform. Except as expressly provided in this Agreement, the Platform is provided to you on an "as is" and "as available" basis, without warranty of any kind. AFOUR, ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS EXPRESSLY DISCLAIM ALL OTHER REPRESENTATIONS, ENDORSEMENTS OR CONDITIONS, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT, COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION, OR THAT DEFECTS WILL BE CORRECTED, OR ANY WARRANTY ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. YOUR SOLE AND EXCLUSIVE REMEDY AND OUR SOLE OBLIGATION TO YOU OR ANY THIRD PARTY FOR ANY CLAIM ARISING FROM YOUR USE OF THE PLATFORM SHALL BE THAT YOU MAY CEASE USING THE PLATFORM AT ANY TIME. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AFOUR DOES NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE PLATFORM ARE FREE OF VIRUSES OR OTHER HARMFUL CODE OR COMPONENTS. YOU ASSUME SOLE RESPONSIBILITY FOR YOUR USE OF THE PLATFORM AND THE SECURITY OF YOUR DEVICES AND DATA. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AFOUR, ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES , mail bombing or crashes, viruses, Trojan horses, worms, logic bombs or other technologically harmful material that may infect your computer equipment, mobile devices, computer programs, data or other proprietary material due to your use of the Platform or your downloading of any material posted on the Platform or on any third party platform linked to the Platform.

Limitation of Liability

AFOUR makes no representations or warranties other than those expressly mentioned in these Terms of Use. The Platform has not been developed to meet your personal needs.

To the maximum extent permitted by applicable law, you acknowledge and agree that AFOUR and its affiliates, directors, officers, employees or agents shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages that you may suffer under any theory of liability in connection with your use of the Platform, including but not limited to lost profits, lost opportunity, loss of data and other intangible losses. By accessing the Platform, you acknowledge that you fully understand the risks of trading, investing and using digital assets and assume full responsibility for those risks.

Anti-Money Laundering and Counter-Terrorist Financing (AML/CTF) and Regulatory Compliance

AFOUR is committed to complying with all applicable laws, regulations and regulatory standards and guidance related to AML/CTF, including but not limited to the International Standards on Combating Money Laundering and the Financing and Proliferation of Terrorism issued by the Financial Action Task Force; the U.S. Bank Secrecy Act and the USA PATRIOT Act; the European Union’s Sixth Anti-Money Laundering Directive (effective June 3, 2021); the Anti-Money Laundering Regulations implemented by the UK Financial Conduct Authority; the Proceeds of Crime (Money Laundering) and Terrorist Financing Act of Canada; the Anti-Money Laundering and Terrorist Financing Ordinance (Chapter 615 of the Laws of Hong Kong) and the Hong Kong Anti-Money Laundering and Counter-Terrorism Financing Guidelines.

AFOUR has implemented a robust AML/CTF compliance program that complies with all applicable AML/CTF requirements to which it is subject.

As described in the Privacy Policy below, you consent to AFOUR collecting and using your personal data to enable AFOUR to comply with AML/CTF and other regulatory requirements.

Availability of Website and Software

The Platform is available on computers and handheld mobile devices running the AFOUR application. AFOUR will make its best efforts to make the Platform available at all times. However, you understand and acknowledge that the Platform is provided over the Internet and mobile networks and therefore the quality and availability of the Platform may be affected by factors beyond AFOUR's reasonable control.

AFOUR is not responsible for the unavailability of the Platform, any difficulty or inability to download or access content on the Platform, or any other system failure that may cause the Platform to be incompletely available or operational.

AFOUR is not responsible for providing you with any technical support or maintenance in relation to the Platform. AFOUR may, at its sole discretion, update, modify or adjust the Platform and its features from time to time to enhance the customer experience. AFOUR is not responsible for any downtime caused by these operations.

Help Desk

AFOUR maintains an online help desk on the Platform to answer your questions regarding the Platform. AFOUR will only address questions regarding the functionality of the Platform and will not provide you with any personalized advice or recommend any system settings on your personal computer or mobile device. AFOUR may upload general tutorials and instructional videos on the Platform to explain the functionality of the Platform. All tutorials, videos and other instructional materials uploaded by AFOUR are for general use only and do not contain any personalized solutions. You use all such services and materials at your own risk.

Privacy

AFOUR is committed to protecting the privacy, confidentiality and security of customer personal information or personal data collected in accordance with all applicable data protection laws. AFOUR is also committed to ensuring that all our employees comply with all our internal policies and standards regarding data security and confidentiality and to protecting your personal data from unauthorized access, unlawful processing or accidental loss, destruction or damage.
                </pre>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang: localStorage.getItem('lang') || 'en',
        };
    },
    created() {
    },
    methods: {

    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    pre {
        // 超出换行
        white-space: pre-wrap;
        word-wrap: break-word;
        // 字体如何向左对齐呢？
        text-align: justify;
        text-justify: inter-ideograph;
        // 字体颜色
        color: #333;
        padding: 15px;
    }

}
</style>
